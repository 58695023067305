<template>
  <div>
    <Header />
    <pageTitle :txt="ttl" :backBtn="backBtn" />
    <div id="meta">
      <div v-on:click="(add = !add), (overlay = !overlay)" class="edit-btn">
        <btnS class="edit" btnTxt="追加" color="positive1" />
      </div>
      <div
        v-on:click="(print = !print), (overlay = !overlay), setPrint()"
        class="print-btn"
      >
        <btnS class="print" btnTxt="印刷" color="primary" />
      </div>
    </div>
    <div id="wrapper">
      <ul class="list">
        <li class="list-head">
          <div class="list-head__item col-name">摘要</div>
          <div class="list-head__item col-sales">区分</div>
          <div class="list-head__item col-sales">税抜価格</div>
          <div class="list-head__item col-sales">消費税</div>
          <div class="list-head__item col-taxin">税込価格</div>
          <div class="list-head__item col-note">備考</div>
        </li>
        <div>
          <li class="list-item" v-for="(row, key) in list" :key="key">
            <!--追加項目ではないもの（商品代） -->
            <div class="list-item__inner" v-if="!row.extra">
              <div class="list-item__data col-name" :class="row.name == '8%割引対象額' ? 'negative' : ''">
                <router-link v-if="row.name != '送料' && row.name !='真空代' && row.name !='オーナー割引' && store.store_name != '全店分（アスハレコーポレーション宛）' && row.name != '8%割引対象額'" :to="'/iseya/shipping/deliver/detail/' + '?date=' + row.nohin_date + '&shopName=' + shopName + '&shopID=' + shopID">
                  {{ row.name }}
                </router-link>
                <span v-else>{{ row.name }}</span>

              </div>
              <div class="list-item__data col-sales number" :class="row.name == '8%割引対象額' ? 'negative' : ''">
                {{ row.tax_class }}%
              </div>
              <div
                class="list-item__data col-sales number"
                :class="{ negative: row.total_price < 0 }"
              >
                {{ row.total_price | comma }}
              </div>
              <div
                class="list-item__data col-sales number"
                :class="{ negative: row.total_price < 0 }"
              >
                {{ row.tax_price | comma }}
              </div>
              <div
                class="list-item__data col-taxin number"
                :class="{ negative: row.total_price < 0 }"
              >
                {{ row.total_tax_price | comma }}
              </div>
              <div class="list-item__data col-note">{{ row.note }}</div>
            </div>

            <!-- 追加項目 表示 -->
            <div
              class="list-item__inner"
              v-if="row.extra && !edit"
              :class="{ extra: row.extra }"
            >
              <div class="list-item__data col-name">{{ row.name }}</div>
              <div class="list-item__data col-sales number tax">
                {{ row.tax_class }}%
              </div>
              <div
                class="list-item__data col-sales number"
                :class="{ negative: row.total_price < 0 }"
              >
                {{ row.total_price | comma }}
              </div>
              <div
                class="list-item__data col-sales number"
                :class="{ negative: row.total_price < 0 }"
              >
                {{ row.tax_price | comma }}
              </div>
              <div
                class="list-item__data col-taxin number"
                :class="{ negative: row.total_price < 0 }"
              >
                {{ row.total_tax_price | comma }}
              </div>
              <div class="list-item__data col-note">{{ row.note }}</div>
              <div v-on:click="onDelete(row)" class="delete-btn">
                <btnS class="delete" btnTxt="削除" color="negative" />
              </div>
            </div>

            <!-- 追加項目 編集 -->
            <div class="list-item__inner" v-if="row.extra && edit">
              <div class="list-item__data col-name">
                <input type="text" v-model="row.name" />
              </div>
              <div class="list-item__data col-sales number">
                <input type="text" v-model="row.tax_class" /> %
              </div>
              <div
                class="list-item__data col-sales number"
                :class="{ negative: row.total_price < 0 }"
              >
                {{ row.total_price | comma }}
              </div>
              <div
                class="list-item__data col-sales number"
                :class="{ negative: row.total_price < 0 }"
              >
                {{ row.tax_price | comma }}
              </div>
              <div
                class="list-item__data col-taxin number"
                :class="{ negative: row.total_price < 0 }"
              >
                {{ row.total_tax_price | comma }}
              </div>
              <div class="list-item__data col-note">{{ row.note }}</div>
            </div>
          </li>

          <!--
          <li class="list-item total">
            <div class="list-item__data col-name">合計</div>
            <div class="list-item__data col-sales number"></div>
            <div class="list-item__data col-sales number">
              {{ total.total_price | comma }}
            </div>
            <div class="list-item__data col-sales number">
              {{ total.tax_price | comma }}
            </div>
            <div class="list-item__data col-taxin number">
              {{ total.total_tax_price | comma }}
            </div>
            <div class="list-item__data col-note"></div>
          </li>
          -->
        </div>
      </ul>

      <ul class="list invoice no-shrink">
        <li class="invoice-title">TOTAL</li>
        <li class="list-item has-border-top">
          <div class="list-item__data invoice-name">8% 対象額</div>
          <div class="list-item__data invoice-data">
            {{ total.eight.total_price | comma }}
          </div>

          <div class="list-item__data invoice-name">8% 消費税</div>
          <div class="list-item__data invoice-data">
            {{ total.eight.tax_price | comma }}
          </div>

          <div class="list-item__data invoice-name">合計</div>
          <div class="list-item__data invoice-data">
            {{ total.eight.total_tax_price | comma }}
          </div>
        </li>
        <li class="list-item">
          <div class="list-item__data invoice-name">10% 対象額</div>
          <div class="list-item__data invoice-data">
            {{ total.ten.total_price | comma }}
          </div>

          <div class="list-item__data invoice-name">10% 消費税</div>
          <div class="list-item__data invoice-data">
            {{ total.ten.tax_price | comma }}
          </div>

          <div class="list-item__data invoice-name">合計</div>
          <div class="list-item__data invoice-data">
            {{ total.ten.total_tax_price | comma }}
          </div>
        </li>
        <li class="list-item total">
          <div class="list-item__data invoice-name">合計</div>
          <div class="list-item__data invoice-data">
            {{ total.total.total_price | comma }}
          </div>

          <div class="list-item__data invoice-name">消費税合計</div>
          <div class="list-item__data invoice-data">
            {{ total.total.tax_price | comma }}
          </div>

          <div class="list-item__data invoice-name">総額</div>
          <div class="list-item__data invoice-data">
            {{ total.total.total_tax_price | comma }}
          </div>
        </li>

      </ul>
    </div>

    <div id="overlay" :class="{ visible: overlay }">
      <printInvoice
        :allDetail="listForPrint"
        v-if="print"
      />

      <div id="fixedMenu" v-if="print">
        <div
          v-on:click="(print = !print), (overlay = !overlay), removeSetPrint()"
        >
          <btnS btnTxt="戻る" color="back" />
        </div>
        <div onclick="print()">
          <btnL btnTxt="この内容で印刷する" color="primary" />
        </div>
      </div>

      <div id="add" v-if="add">
        <div class="dialog">
          <p class="dialog-ttl">請求情報編集</p>
          <div class="dialog-form">
            <div class="dialog-form__item name">
              <label>摘要</label>
              <input type="text" v-model="invoiceName" />
              <div class="error">{{ errors.name }}</div>
            </div>
            <div class="dialog-form__item tax">
              <label>税区分</label>
              <div class="select">
                <select v-model="tax_class">
                  <option value="8">8%</option>
                  <option value="10">10%</option>
                </select>
                <div class="error">{{ errors.tax_class }}</div>
              </div>
            </div>
            <div class="dialog-form__item price">
              <label>税抜価格</label>
              <input type="text" v-model="total_price" />
              <div class="error">{{ errors.total_price }}</div>
            </div>
            <div class="dialog-form__item note">
              <label>備考</label>
              <input type="text" v-model="note" />
              <div class="error">{{ errors.note }}</div>
            </div>
          </div>
        </div>
        <div class="overlay-btn">
          <div v-on:click="(add = !add), (overlay = !overlay)">
            <btnS btnTxt="戻る" color="back" />
          </div>
          <div>
            <btnL btnTxt="この内容で登録する" color="primary" @click="save" />
          </div>
        </div>
      </div>

      <div id="delete" v-if="delete_">
        <div class="dialog">
          <p class="dialog-ttl">商品情報削除</p>
          <div class="dialog-form">
            <div class="dialog-form__item">
              <p class="dialog-body center">
                「{{ overlayName }}」を削除しようとしています。
                <br />
                本当に削除しますか？
              </p>
            </div>
          </div>
        </div>
        <div class="overlay-btn">
          <div @click="(delete_ = !delete_), (overlay = !overlay)">
            <btnS btnTxt="戻る" color="back" />
          </div>
          <div>
            <btnL btnTxt="削除する" color="negative" @click="goDelete" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;

#meta {
  width: 100%;
  height: 75px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  padding: 0 30px 0 15px;
  position: relative;
}

.edit-btn {
  margin-left: auto;
}

.print-btn {
  margin-left: 30px;
}

#wrapper {
  box-sizing: border-box;
  padding: 0 0 160px;
}

.list {
  box-sizing: border-box;
  @extend .f_body;
  display: flex;
  flex-wrap: wrap;
  width: 1282px;
  margin: 0 auto;
  border-left: solid 1px variables.$bg-gray-2;
  margin-bottom: 30px;
}

.list li {
  width: 100%;
  position: relative;
  &.has-border-top {
    border-top: solid 1px variables.$txt-default;
  }
  &.total {
    background: variables.$bg-gray-1;
    &.visible {
      display: flex;
    }
    &:before {
      content: "";
      width: 100%;
      height: 1px;
      background: variables.$txt-default;
      position: absolute;
      left: 0;
      top: 0;
    }
    &:after {
      content: "";
      width: 100%;
      height: 1px;
      background: variables.$txt-default;
      position: absolute;
      top: -2px;
      left: 0;
    }
  }
}

.list-head {
  display: flex;
  height: 60px;
  font-size: 1.2rem;
  letter-spacing: 0.05em;
  box-sizing: border-box;
  border-top: solid 1px variables.$bg-gray-2;
}

.list-head__item {
  height: 100%;
  box-sizing: border-box;
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-item {
  display: flex;
  flex-wrap: wrap;
  height: 60px;
  position: relative;
  a {
    color: #000;
    text-decoration: underline;
    transition: .3s ease-in-out;
    &:hover {
      opacity: .6
    }
  }
  &.total {
    &:before {
      content: "";
      width: 100%;
      height: 1px;
      background: variables.$txt-default;
      position: absolute;
      left: 0;
      top: 0;
    }
    &:after {
      content: "";
      width: 100%;
      height: 1px;
      background: variables.$txt-default;
      position: absolute;
      top: -2px;
      left: 0;
    }
  }

  &:nth-of-type(even) {
    background: variables.$bg-gray-1;
  }

  .delete-btn {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    pointer-events: none;
    opacity: 0;
    z-index: 5;
    transition: opacity 0.3s ease-in-out;

    .delete {
      cursor: pointer;
    }
  }

  &:hover .delete-btn {
    opacity: 1;
    pointer-events: all;
  }
}

.list-item__inner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.list-item__data {
  box-sizing: border-box;
  padding: 0 15px;
  height: 100%;
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  display: flex;
  align-items: center;
  &.number {
    justify-content: flex-end;
    input {
      text-align: right;
    }
  }
  &.negative {
    color: variables.$negative;
  }

  input {
    width: calc(100% - 10px);
    margin-right: 10px;
    border: solid 1px variables.$bg-gray-2;
    height: 40px;
    box-sizing: border-box;
    padding: 0 10px;
  }
}

.col-name {
  width: 450px;
}

.col-sales {
  width: 130px;
}



.col-taxin {
  width: 140px;
}

.col-note {
  width: 300px;
}

.list.invoice {
  border-bottom: solid 1px variables.$txt-default;
  li {
    height: 50px;
  }
}
.invoice-title {
  max-width: calc(100% / 6);
  max-height: 40px;
  background: variables.$txt-default;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invoice-name,
.invoice-data {
  width: calc(100% / 6);
  justify-content: flex-end;
}

.invoice-name {
  background: variables.$bg-gray-1;
  border-left: solid 1px variables.$txt-default;
}

.invoice-data {
  &:last-of-type {
    border-right: solid 1px variables.$txt-default;
  }
}


#overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(59, 64, 67, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease;
  box-sizing: border-box;
  padding: 30px 0 120px;
  overflow: scroll;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}
.print-paper {
  //width: 668px;
  //height: 945px;
  background: #fff;
  box-sizing: border-box;
  padding: 30px;

  margin-left: auto;
  margin-right: auto;
  .list {
    width: 606px;
    //transform: scale(0.69);
    transform-origin: top left;
    font-size: 1rem;
    &.invoice {
      margin-top: 30px;
    }

  }

    .list li {
      height: 23px;
    }

  .list-item {
    height: 30px;
  }
  .col-name {
    width: 185px;
  }
  .col-sales,
  .col-taxin {
    width: 90px;
  }
  .col-tax {
    width: 60px;
  }
  .col-note {
    width: 90px;
  }
}

#print-inner {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#print-meta {
  margin-bottom: 30px;
}

.print-meta__right {
  position: absolute;
  right: 30px;
  top: 50px;
  font-size: 1rem;
  letter-spacing: 0.06em;
}

.print-time {
  text-align: right;
}

.print-ttl {
  text-align: center;
  @extend .f_pageTtl;
  margin-bottom: 10px;
}

.print-period {
  text-align: center;
  font-size: 1.2rem;
  letter-spacing: 0.06em;
  margin-bottom: 60px;
}

.print-shopName,
.print-shopTel {
  font-size: (1.5 * 0.69rem);
  letter-spacing: 0.06em;
  margin-right: (30 * 0.69px);
}

#fixedMenu {
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 89px;
  background: transparent;
  backdrop-filter: blur(5.3040900230407715px);
  --webkit-backdrop-filter: blur(5.3040900230407715px);
  background-color: rgba(255, 255, 255, 0.33);

  display: flex;
  justify-content: center;
  align-items: center;
  .buttonS {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(0, -50%);
  }
}

#add,
#edit,
#delete {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialog {
  box-sizing: border-box;
  width: 800px;
  padding: 30px 50px 50px;
  background: #fff;
  margin: 0 auto 25px;
}

.dialog-ttl {
  @extend .f_pageTtl;
  margin-bottom: 15px;
  text-align: center;
}

.dialog-form {
  display: flex;
  flex-wrap: wrap;
}

.dialog-form__item {
  @extend .f_caption;
  margin-top: 35px;
  &.name {
    width: 220px;
  }
  &.tax {
    width: 160px;
  }
  &.price {
    width: 120px;
  }
  &.note {
    width: 400px;
  }
  &.name,
  &.tax {
    margin-right: 20px;
  }
  label {
    margin-bottom: 5px;
    display: block;
  }
  input,
  select {
    box-sizing: border-box;
    border: solid 1px variables.$bg-gray-3;
    border-radius: 3px;
    background: #fff;
    width: 100%;
    height: 28px;
    padding: 0 12px;
  }
  .select {
    position: relative;
    &:after {
      content: "";
      pointer-events: none;

      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: variables.$txt-default transparent transparent transparent;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%);
    }
  }
}

.overlay-btn {
  display: flex;
  justify-content: center;
  width: 800px;
  height: 60px;
  position: relative;
  margin: 0 auto;
  .buttonS {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}
</style>
<style lang="scss">
.meta-calendar__input {
  color: rgba(255, 255, 255, 0);
}
</style>
<script>
import Header from "@/components/iseya/header";
import pageTitle from "@/components/iseya/pageTitle";
import btnS from "@/components/btn/btnS";
import btnL from "@/components/btn/btnL";
import Global from "@/components/mixins/Global.vue";
import ApiClient from "@/module/api";
import Const from "@/module/const";
import printInvoice from '../../../components/iseya/printInvoice.vue';

export default {
  name: "ShopHome",
  components: {
    Header,
    pageTitle,
    btnS,
    btnL,
    printInvoice
  },
  mixins: [Global],
  data: function() {
    return {
      backBtn: true,
      print: false,
      today: "",
      overlay: false,
      add: false,
      edit: false,
      delete_: false,
      ttl: "当月の請求予定",
      shopName: "",
      list: [],
      total: {},
      store: {},
      invoiceName: "",
      tax_class: "8",
      total_price: 0,
      note: "",
      errors: {},
      targetID: "",
      overlayName: "",
      shopID: "",
      listForPrint: [],
    };
  },
  props: {
    name: String
  },
  async created() {
    this._redraw();
    this.shopID = this.$route.query.shopID;
    this.shopName = this.$route.query.shopName;
  },
  computed: {
    IseyaZip: function() {
      return Const.IseyaZip;
    },
    IseyaAddress: function() {
      return Const.IseyaAddress;
    },
    IseyaTel: function() {
      return Const.IseyaTel;
    },
    IseyaFax: function() {
      return Const.IseyaFax;
    }
  },
  methods: {
    async save() {
      this.errors = {};
      const param = {
        store_id: this.$route.query.shopID,
        target_date: this.ymdFormat(new Date()),
        name: this.invoiceName,
        tax_class: this.tax_class,
        total_price: this.total_price,
        tax_price: 0,
        total_tax_price: 0,
        note: this.note
      };
      param.total_tax_price = parseInt(
        this.total_price * (1 + this.tax_class / 100),
        10
      );
      param.tax_price = param.total_tax_price - param.total_price;
      const api = new ApiClient();
      const result = await api.post("/iseya/invoice/save", param);
      const checkResult = api.getErrors(result);
      if (checkResult.isHit) {
        this.errors = checkResult.errors;
        return;
      }
      this.edit = !this.edit;
      this.overlay = !this.overlay;
      this._redraw();
    },
    async onDelete(row) {
      this.targetID = row.id;
      this.overlayName = row.name;
      this.overlay = true;
      this.delete_ = true;
    },
    async goDelete() {
      const api = new ApiClient();
      const param = {
        id: this.targetID
      };
      await api.post("/iseya/invoice/delete", param);
      this.overlay = false;
      this.delete_ = false;
      this._redraw();
    },
    async _redraw() {
      const api = new ApiClient();
      const param = {
        store_id: this.$route.query.shopID
      };
      const result = await api.post("/iseya/invoice/detail", param);
      console.log("result");
      console.log(result);
      this.list = result.list.filter(list => list.total_price != 0);
      this.total = result.total;
      this.store = result.store;
      //印刷用のリストを作成
      this.listForPrint.push({
        list: this.list,
        total: this.total,
        store: this.store
      });
    },
    setPrint() {
      let html = document.querySelector("html");
      html.classList.add("print-portrait");
      document.body.classList.add("print-portrait");
    },
    removeSetPrint() {
      let html = document.querySelector("html");
      html.classList.remove("print-portrait");
      document.body.classList.remove("print-portrait");
    }
  }
};
</script>
